<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>One Vision</h1>
    </header>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import constantData from '@/json/data.json'

export default {
  name: 'one-vision',
  data() {
    return {}
  },
  components: {},
  computed: {
    ...mapGetters({
      userId: 'user/userId',
    }),
  },
  created() {
    window.open(`${constantData.one_vision_link}${this.userId}`)
    this.$router.push({ path: '/' })
  },
}
</script>

<style lang="scss">